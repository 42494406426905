<template>
  <v-container class="px-0 pb-6">
    <v-form>
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-row class="mt-9 mb-9">
            <v-col cols="12">
              <h2 class="mb-6" v-text="$t('club.clubEnrollmentForm.staffData.title')" />
              <v-row v-for="(staffer, index) in form.staff" :key="staffer.listId">
                <v-col cols="12">
                  <v-divider v-if="form.staff.length > 1 && index != 0" />
                </v-col>
                <v-col cols="12" class="d-flex align-center justify-space-between">
                  <h3 v-text="`${$t('club.staff')} ${index + 1}`" />
                  <v-btn v-if="form.staff.length > 1"
                         class="w-auto" icon small color="primary" @click="form.staff.splice(index, 1)"
                  >
                    <span>{{ $t('common.delete') }}</span>
                    <v-icon dark class="mx-1">
                      delete_outline
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="py-1 px-3">
                  <v-text-field
                    v-model="staffer.firstName" :label="$t('user.fields.firstName')"
                    :error-messages="getFieldErrors(`staff.$each.$iter.${index}.firstName`)"
                    outlined dense
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-1 px-3">
                  <v-text-field
                    v-model="staffer.lastName" :label="$t('user.fields.lastName')"
                    :error-messages="getFieldErrors(`staff.$each.$iter.${index}.lastName`)"
                    outlined dense
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-1 px-3">
                  <v-text-field
                    v-model="staffer.email" :label="$t('user.fields.email')"
                    :error-messages="getFieldErrors(`staff.$each.$iter.${index}.email`)"
                    outlined dense
                  >
                    <template #append>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon color="primary" v-on="on" @click="copyToClipboard(staffer.email)">
                            content_copy
                          </v-icon>
                        </template>
                        {{ $t('club.editClub.copyLink') }}
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-1 px-3">
                  <phone-field
                    v-model="staffer.phone" outlined dense
                    :error-messages="getFieldErrors(`staff.$each.$iter.${index}.phone.code`) || getFieldErrors(`staff.$each.$iter.${index}.phone.number`)"
                    @blur="$v.form.staff.$each[index].phone.$touch()"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-1 px-3">
                  <v-text-field
                    v-model="staffer.position" :label="$t('club.editClub.charge')"
                    :error-messages="getFieldErrors(`staff.$each.$iter.${index}.position`)"
                    outlined dense
                  />
                </v-col>
                <v-col v-if="index === form.staff.length - 1" cols="12">
                  <v-btn class="w-auto" icon small color="primary" @click="form.staff.push({
                    firstName: null, lastName: null, position: null,
                    phone: { country: null, code: null, number: null }, email: null, listId: Math.random()
                  })"
                  >
                    <v-icon dark class="mx-1">
                      add_box
                    </v-icon>
                    <span>{{ $t('club.editClub.addOtherStaff') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import preventReload from '@/utils/mixins/preventReload'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'AddStaff',
  components: {
    PhoneField: () => import('@/components/formFields/PhoneField'),
  },
  mixins: [formUtils, preventReload],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    globalForm: { type: Object, required: true },
  },
  data() {
    return {
      form: {
        staff: this.globalForm.staff ?? [{
          firstName: null, lastName: null, email: null, phone: { country: null, code: null, number: null }, position: null, listId: Math.random(),
        }],
      },
    }
  },
  validations() {
    return {
      form: {
        staff: {
          required,
          $each: {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            phone: { code: { required }, number: { required } },
            position: { required },
          },
        },
      },
    }
  },
  created: () => window.scrollTo(0, 0),
}
</script>
